import * as React from "react";
import Header from "../components/Header";
import { withPrefix } from "gatsby";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import backgroundMarseille from "../images/aboutus/main.jpg";
import backgroundPort from "../images/aboutus/port.jpg";
import "../scss/style.scss";
import { Helmet } from "react-helmet";

// markup
const AboutPage = () => {
  return (
    <main className="page">
      <title>
        Société de sécurité spécialisée dans votre protection - Services et
        Prestations
      </title>
      <div id="wrapper" className="wrapper">
        <Header />
        <Banner
          title="À propos de nous"
          content="Depuis 2018, nous nous efforçons de vous conduire où vous en avez besoin."
          background={backgroundMarseille}
          position="left"
        />
        <Banner
          title="Vos projets sont les notres"
          content="Vous conduire à bon port est notre mission."
          background={backgroundPort}
          position="right"
        />
      </div>
      <Footer />
    </main>
  );
};

export default AboutPage;
